import clsx from 'clsx';
import { Field, FormikProvider, useFormik } from 'formik';
import loadScript from 'load-script2';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useMarketo } from '../../../../contexts/marketo-context';
import { useFormValidation } from '../../../../hooks/form-validation';
import { useMarketoFormSubmit } from '../../../../hooks/marketo';
import { getCookie, sendTrackingData, sleep } from '../../../../utils';
import {
	validateEmail,
	validatePhoneNumber,
	validateString,
} from '../../../../utils/marketo';
import { Button } from '../../../atoms/button';
import { CallUs } from '../../../atoms/call-us-ppc';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { TextInput } from '../../../atoms/text-input';

function InYourAreaFormikForm({ formId, ctaText, pageUrl, setFormResponse }) {
	const MktoForms2Instances = useMarketo();
	const initialValidations = {
		FirstName: { isValid: true },
		isClient: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Date: { isValid: true },
		Time: { isValid: true },
		Phone: { isValid: true },
		Company: { isValid: true },
	};
	const [isLoading, setIsLoading] = useState(false);
	const [technicalError, setTechnicalError] = React.useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);
	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');

	const title = ctaText || 'Register your interest';
	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const dateRef = useRef(null);
	const timeRef = useRef(null);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
		timeslot: '',
		preferredTimeInYourArea: '',
		PersonSource: 'Website - Confirm Webinar Web Lead',
		LeadSource: 'Website - Confirm Webinar Web Lead',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		MSCLKID__c: msclkidCookie,
		MSCLKID_last: msclkidLastCookie,
		GCLID__c: gclidCookie,
		gCLID_last: gclidLastCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
		socialCampaignID: socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: true,
	});

	const submitHandler = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) {
			setIsLoading(false);
			return;
		}

		const payloadData = {
			...initialValues,
			FirstName: formEntries.FirstName,
			LastName: formEntries.LastName,
			Phone: formEntries.Phone,
			Email: formEntries.Email,
			Company: formEntries.Company,
			timeslot: formEntries.Date,
			preferredTimeInYourArea: formEntries.Time,
			PersonSource: 'Website - Confirm Webinar Web Lead',
		};

		await sleep(500);
		handleClickTracking();
		useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
			.then(() => {
				formik.setStatus('submitted');
				setFormResponse(true);
			})
			.catch(() => {
				formik.setStatus('error');
				setTechnicalError(true);
			})
			.finally(() => {
				handleClickTracking();
				setIsLoading(false);
			});
	};

	useEffect(() => {
		const loadForm = () =>
			loadScript(
				'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-lon04.marketo.com'
					).loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [MktoForms2Instances, formId]);

	useEffect(() => {
		if (!validations.FirstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.LastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.Email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (validations.Phone.isValid === false && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.Company.isValid && companyRef.current) {
			return companyRef.current.focus();
		}
		if (!validations.Date.isValid && dateRef.current) {
			return dateRef.current.focus();
		}
		if (!validations.Time.isValid && timeRef.current) {
			return timeRef.current.focus();
		}
	}, [validations]);
	return technicalError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<FormikProvider value={formik}>
			<form
				data-formid={formId}
				data-forminstance="one"
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto overflow-hidden grow "
			>
				<div className="flex flex-col grow bg-white">
					<fieldset
						className={clsx('flex flex-col pt-5 bg-white grow ')}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="FirstName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="FirstName"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											First name&#42;
										</label>
										<TextInput
											id="FirstName"
											name="FirstName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={firstNameRef}
											showValidation={
												!validations.FirstName
													.isValid &&
												!firstNameRef.current.value
											}
											validationMessage={
												!validations.FirstName.isValid
													? 'Please enter your first name'
													: null
											}
											{...field}
											space={false}
											placeholder="Jane"
											validateOnBlur
										/>
									</div>
								)}
							</Field>

							<Field name="LastName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="LastName"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Last name&#42;
										</label>
										<TextInput
											id="LastName"
											name="LastName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={lastNameRef}
											showValidation={
												!validations.LastName.isValid &&
												!lastNameRef.current.value
											}
											validationMessage={
												!validations.LastName.isValid
													? 'Please enter your last name'
													: null
											}
											{...field}
											space={false}
											placeholder="Smith"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="Company" validate={validateString}>
								{({ field }) => (
									<div className="w-full ">
										<label
											htmlFor="Company"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Company name&#42;
										</label>
										<TextInput
											id="Company"
											name="Company"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={companyRef}
											showValidation={
												!validations.Company.isValid &&
												!companyRef.current.value
											}
											validationMessage={
												!validations.Company.isValid
													? 'Please enter your company'
													: null
											}
											{...field}
											space={false}
											placeholder="Company LTD"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="Email" validate={validateEmail}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Email"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Email&#42;
										</label>
										<TextInput
											id="Email"
											type="email"
											name="Email"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={emailRef}
											showValidation={
												!validations.Email.isValid &&
												!emailRef.current.value
											}
											validationMessage="Please enter your email address"
											{...field}
											space={false}
											placeholder="jane.smith@gmail.com"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
							<Field name="Phone" validate={validatePhoneNumber}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Phone"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Phone&#42;
										</label>
										<TextInput
											id="Phone"
											name="Phone"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={phoneNumberRef}
											showValidation={
												!validations.Phone.isValid ===
													true &&
												phoneNumberRef.current
											}
											validationMessage="Please enter a phone number"
											{...field}
											space={false}
											placeholder="07997 210 007"
											type="tel"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="Date" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Date"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Preferred date&#42;
										</label>
										<TextInput
											id="Date"
											type="date"
											name="Date"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={emailRef}
											showValidation={
												!validations.Date.isValid &&
												!dateRef.current.value
											}
											validationMessage="Please enter a preferred date"
											{...field}
											space={false}
											placeholder="dd/mm/yyyy"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
							<Field name="Time" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Time"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Preferred time&#42;
										</label>
										<TextInput
											id="Time"
											name="Time"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={phoneNumberRef}
											showValidation={
												!validations.Phone.isValid ===
													true &&
												phoneNumberRef.current
											}
											validationMessage="Please enter a preferred time"
											{...field}
											space={false}
											placeholder="09:00"
											type="time"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
					</fieldset>
				</div>

				<div className="flex flex-col items-center bg-white  lg:flex-row lg:justify-between mt-20">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								formik.isSubmitting || isLoading
									? 'justify-center loading'
									: null
							)}
							disabled={formik.isSubmitting || isLoading}
						>
							<span
								className={clsx(
									isLoading || formik.isSubmitting
										? 'invisible'
										: null
								)}
							>
								{title}
							</span>
							{isLoading || formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	);
}
InYourAreaFormikForm.defaultProps = {
	formId: 0,
	ctaText: 'Submit',
	pageUrl: '',
	setFormResponse: undefined,
	showClientNumber: false,
	phoneNumber: '',
};

InYourAreaFormikForm.propTypes = {
	formId: PropTypes.number,
	ctaText: PropTypes.string,
	pageUrl: PropTypes.string,
	setFormResponse: PropTypes.func,
	showClientNumber: PropTypes.bool,
	phoneNumber: PropTypes.string,
};

export default InYourAreaFormikForm;
