/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { Title } from '../components/atoms/title';
import { Drawer } from '../components/atoms/drawer';
import { CtaBanner } from '../components/atoms/cta-banner';

import { FeefoTestimonials } from '../components/molecules/feefo';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBanner } from '../components/organisms/advice-banner';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { SectorCard } from '../components/molecules/sector-card';

import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { HrefLang } from '../components/templates/hrefLang';

const ViewAllSectorsPage = ({
	data,
	location,
	trustPilotPublicBusinessUnit,
	trustPilotBusinessUnitReviews,
}) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const {
		noIndex,
		noFollow,
		metaInformation,
		adviceBanner,
		heading,
		subHeading,
		ctaBanner,
		ctaBannerDisplayOnPage,
	} = data.datoCmsAllSectorsPage;

	const allSectors = data.allDatoCmsSectorPage.nodes;

	const metaContent = {
		noIndex,
		noFollow,
		metaInformation,
	};

	const adviceBannerContent = {
		...adviceBanner,
		title: adviceBanner.title.value.document.children,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.allSectors,
			location,
			data: data.datoCmsAllSectorsPage,
		}),
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsAllSectorsPage.internal.type,
		data.datoCmsAllSectorsPage
	);
	const { locale: urlLocale } = data.datoCmsAllSectorsPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsAllSectorsPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Title
				title={heading}
				subtitle={subHeading}
				className="px-3 mx-auto max-w-m-screen lg:px-8"
			/>

			<section className="px-4 mx-auto max-w-m-screen">
				<h2 className="mb-6 text-2xl">Browse sectors:</h2>
				<div className="gap-4 md:grid md:grid-cols-2 lg:grid-cols-3">
					{allSectors.map((sector, index) => (
						<SectorCard
							key={sector.id}
							cardContent={sector}
							index={index}
						/>
					))}
				</div>
			</section>

			{urlLocale !== 'en-CA' ? (
				<FeefoTestimonials locale={urlLocale} />
			) : null}

			{ctaBannerDisplayOnPage ? (
				<section
					aria-labelledby={ctaBanner.id}
					className="px-3 mx-auto max-w-[1084px]"
				>
					<CtaBanner
						drawerRef={adviceFormRef}
						className="!px-7 lg:!gap-10"
						headingClassNames="!text-3xl md:!text-4xl md:!leading-snug"
						{...ctaBanner}
					/>
				</section>
			) : null}

			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>

			<TrustpilotReviews
				locale={urlLocale}
				businessUnit={trustPilotPublicBusinessUnit}
				reviews={trustPilotBusinessUnitReviews}
				className="pt-12 !pb-0"
				wrapperClassName="pb-12 border-b-1 border-b-blue-200"
			/>

			{urlLocale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedAllEventsPage = ({ pageContext, data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<ViewAllSectorsPage
			data={data}
			pageDirectory={pageContext.pageDirectory}
			location={location}
			trustPilotPublicBusinessUnit={
				pageContext.trustPilotPublicBusinessUnit
			}
			trustPilotBusinessUnitReviews={
				pageContext.trustPilotBusinessUnitReviews
			}
		/>
	</DefaultLayout>
);

export default WrappedAllEventsPage;

export const query = graphql`
	query allSectors($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsAllSectorsPage(locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			id
			internal {
				type
			}
			noIndex
			noFollow
			heading
			subHeading
			ctaBanner {
				id: originalId
				title: ctaBannerTitle
				content
				linkCopy
				link
				ctaOpenForm
				image {
					alt
					url
					gatsbyImageData(
						width: 415
						height: 315
						layout: CONSTRAINED
					)
				}
			}
			ctaBannerDisplayOnPage
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}
		allDatoCmsSectorPage(
			sort: { fields: title, order: ASC }
			filter: { locale: { eq: $locale } }
		) {
			nodes {
				slug
				title
				intro
				buttonText
				mainHeader {
					primaryImage {
						gatsbyImageData
					}
				}
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
