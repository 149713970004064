// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Head } from '../components/templates/head';
import { HrefLang } from '../components/templates/hrefLang';
import { DefaultLayout } from '../layouts/default/payroll';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { pageTypes } from '../utils/url-helpers';

import TickIcon from '../assets/tick.inline.svg';
import PayrollFooter from '../components/glu/organisms/payroll-footer';
import PayrollFormikForm from '../components/glu/organisms/payroll-form';
import PayrollHeader from '../components/molecules/payroll-header';
import useIsMobile from '../hooks/mobile';

const PayrollPage = ({ data, location, phoneNumber }) => {
	const {
		// title,
		// id,
		showClientNumber,
		content,
		image,
		smallImage,
		formTitle,
		ctaText,
		formId,
		showSocialButton,
		copyrightText,
	} = data.datoCmsPayrollPage;
	const [formResponse1, setFormResponse1] = useState(false);
	const metaContent = {
		noIndex: data.datoCmsPayrollPage.noIndex,
		noFollow: data.datoCmsPayrollPage.noFollow,
		metaInformation: data.datoCmsPayrollPage.metaInformation,
		fallbackTitle: data.datoCmsPayrollPage.title,
		fallbackDescription: data.datoCmsPayrollPage.longIntroduction,
		canonicalUrl: data.datoCmsPayrollPage?.canonicalUrl || location.href,
	};
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsPayrollPage,
		}),
		image: getImageSeoSchema(data.datoCmsPayrollPage?.image),
	};
	// eslint-disable-next-line no-unused-vars
	const [formResponse2, setFormResponse2] = useState(false);
	const scrollRef = useRef();
	const isMobile = useIsMobile();
	const scroll = () =>
		setTimeout(() => {
			window.scrollTo({
				top: scrollRef?.current?.offsetTop,
				left: 0,
				behavior: 'smooth',
			});
		}, 500);

	useEffect(() => {
		if (formResponse1 && !formResponse2 && !isMobile) {
			scroll();
		}
	}, [formResponse1, formResponse2, isMobile]);
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPayrollPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<div className="relative mx-auto lg:mt-10 mt-3 mb-8 w-full max-w-screen-xxs lg:rounded-sm border border-grey-400/30 bg-white shadow-md md:mb-0 md:max-w-5xl">
				<div className="lg:flex">
					<div className="border-b border-grey-400/30 lg:hidden">
						<img
							src={smallImage.url}
							alt="payroll-register-desktop"
							className="w-full h-48 object-fill object-center"
						/>
					</div>
					<div className="hidden border-r border-grey-400/30 lg:block lg:flex-initial">
						<img
							src={image.url}
							alt="payroll-register-desktop"
							width={370}
							className="rounded-l-sm pb-4"
						/>
					</div>
					<div className="p-8 md:flex-1 lg:px-16">
						<PayrollHeader title={formTitle} content={content} />
						{formResponse1 ? (
							<div className="flex flex-col overflow-hidden rounded-sm border border-grey-400/30 mt-10 card flip">
								<h3 className="w-full p-4 text-2xl text-white bg-brand-blue-400 lg:p-8">
									<TickIcon
										className="inline-block w-5 h-auto mr-4 text-white shrink-0"
										aria-hidden="true"
									/>{' '}
									Thank you for registering your interest.
								</h3>
								<div className="p-4 bg-white lg:p-8">
									<p className="mb-5 font-bold text-brand-red-400">
										Our team will be in touch soon to
										discuss your enquiry.
									</p>
								</div>
							</div>
						) : (
							<>
								<PayrollFormikForm
									formId={Number(formId)}
									ctaText={ctaText}
									pageUrl={
										typeof window !== 'undefined'
											? window.location.href
											: ''
									}
									phoneNumber={phoneNumber}
									setFormResponse={setFormResponse1}
									showClientNumber={showClientNumber}
								/>
								<PayrollFooter />
							</>
						)}
					</div>
				</div>
			</div>
			<footer className="mx-auto mb-6 pb-20 mt-16 max-w-screen-xxs text-center md:max-w-full relative">
				<ul className="flex flex-col justify-center md:flex-row">
					<li className="inline-block pb-2 text-base font-semibold">
						<a
							className="duration-300 hover:text-brand-200"
							href="https://www.peninsulagrouplimited.com/resource-hub/"
						>
							Get Help
						</a>
						<span className="mx-1 lg:mx-3 hidden md:inline-block">
							•
						</span>
					</li>
					<li className="inline-block pb-2 text-base font-semibold">
						<a
							className="duration-300 hover:text-brand-200"
							href="/about/careers/"
						>
							About Us
						</a>
						<span className="mx-1 lg:mx-3 hidden md:inline-block">
							•
						</span>
					</li>
					<li className="inline-block pb-2 text-base font-semibold">
						<a
							className="duration-300 hover:text-brand-200"
							href="/contact-us/"
						>
							Contact us
						</a>
						<span className="mx-1 lg:mx-3 hidden md:inline-block">
							•
						</span>
					</li>
					<li className="inline-block pb-2 text-base font-semibold">
						<a
							className="duration-300 hover:text-brand-200"
							href="/legal/privacy-policy/"
						>
							Terms and Privacy
						</a>
						<span className="mx-1 lg:mx-3 hidden">•</span>
					</li>
				</ul>
				<p className="mb-4 text-base md:inline-block">
					{copyrightText}
				</p>
				{showSocialButton && (
					<div className="flex flex-row justify-center">
						<a
							aria-label="Facebook"
							href="https://www.facebook.com/PeninsulaUK/?fref=ts"
							title="Facebook"
							data-footer-link="social"
							data-testid="social-facebook"
							target="_blank"
							rel="noreferrer"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 37 37"
								width="36"
								height="36"
								className="mr-1"
								aria-hidden="true"
							>
								<circle
									cx="18.5"
									cy="18.5"
									r="17.5"
									stroke="currentColor"
									strokeWidth="2"
								/>
								<path
									fill="currentColor"
									d="m21.723 20 .445-2.896h-2.779v-1.879c0-.792.388-1.564 1.633-1.564h1.263v-2.465S21.139 11 20.043 11c-2.289 0-3.784 1.387-3.784 3.898v2.206h-2.544V20h2.544v7h3.13v-7z"
								/>
							</svg>
						</a>

						<a
							aria-label="YouTube"
							href="https://www.youtube.com/channel/UCWQ5bV3Xyvk2ka75LrDVXXg"
							title="Youtube"
							data-footer-link="social"
							data-testid="social-youtube"
							target="_blank"
							rel="noreferrer"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								strokeWidth="2"
								viewBox="0 0 37 37"
								width="36"
								height="36"
								className="mr-1"
								aria-hidden="true"
							>
								<circle
									cx="18.5"
									cy="18.5"
									r="17.5"
									stroke="currentColor"
								/>
								<path
									fill="currentColor"
									d="M21.038 22.636v2.338q0 .742-.432.742-.255 0-.498-.244v-3.335q.243-.244.498-.244.432 0 .432.743m3.746.01v.51h-.997v-.51q0-.753.498-.753.5 0 .499.754zM14.08 20.232h1.185V19.19h-3.457v1.042h1.164v6.305h1.108zm3.191 6.305h.986v-5.474h-.986v4.189q-.332.465-.632.465-.2 0-.232-.233a3 3 0 0 1-.011-.387v-4.034h-.987v4.333q0 .543.089.809.133.41.643.41.531 0 1.13-.676zm4.754-1.64v-2.183q0-.809-.1-1.097-.189-.62-.787-.62-.555 0-1.03.598V19.19h-.986v7.347h.986v-.532q.498.61 1.03.61.599 0 .787-.61.1-.3.1-1.108m3.745-.11v-.145h-1.008q0 .565-.023.676-.077.4-.443.4-.51 0-.51-.765v-.964h1.984v-1.142q0-.875-.3-1.285-.431-.565-1.174-.565-.753 0-1.185.565-.31.41-.31 1.285v1.917q0 .876.32 1.286.434.564 1.197.565.798 0 1.197-.588.199-.298.233-.598.022-.099.022-.643zm-6.737-10.118v-2.326q0-.765-.477-.765-.476 0-.476.765v2.326q0 .777.476.776.477 0 .477-.776M27 22.99q0 2.593-.288 3.879a2.1 2.1 0 0 1-.643 1.097 1.94 1.94 0 0 1-1.13.51q-2.04.232-6.15.232c-4.11 0-4.79-.077-6.15-.233a1.96 1.96 0 0 1-1.136-.51 2.03 2.03 0 0 1-.637-1.096q-.288-1.241-.288-3.879 0-2.592.288-3.878a2.1 2.1 0 0 1 .643-1.097 2 2 0 0 1 1.141-.52q2.027-.223 6.14-.223 4.11 0 6.149.222.642.078 1.136.521.492.444.637 1.097.288 1.24.288 3.878M15.941 8.851h1.13l-1.34 4.421v3.003h-1.108v-3.003q-.157-.82-.676-2.349a96 96 0 0 1-.72-2.072H14.4l.787 2.914zm4.1 3.69v1.94q0 .897-.31 1.307-.42.565-1.175.565-.742 0-1.163-.565-.31-.422-.31-1.308v-1.939q0-.886.31-1.296.42-.566 1.163-.566.754 0 1.175.566.31.41.31 1.296m3.712-1.795v5.53h-1.008v-.61q-.588.687-1.141.687-.51 0-.654-.41-.09-.266-.089-.831v-4.366h1.008v4.067q0 .365.012.387.034.244.232.244.3 0 .632-.476v-4.222z"
								/>
							</svg>
						</a>
						<a
							aria-label="LinkedIn"
							href="https://www.linkedin.com/company/peninsula-uk/"
							title="LinkedIn"
							data-footer-link="social"
							data-testid="social-linkedin"
							target="_blank"
							rel="noreferrer"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 37 37"
								width="36"
								height="36"
								aria-hidden="true"
							>
								<circle
									cx="18.5"
									cy="18.5"
									r="17.5"
									stroke="currentColor"
									strokeWidth="2"
								/>
								<path
									fill="currentColor"
									d="M15.134 25H12.23v-9.347h2.903zM13.68 14.378a1.695 1.695 0 0 1-1.681-1.697 1.681 1.681 0 0 1 3.362 0c0 .928-.753 1.697-1.681 1.697M25.997 25H23.1v-4.55c0-1.084-.022-2.475-1.51-2.475-1.509 0-1.74 1.178-1.74 2.397V25h-2.9v-9.347h2.785v1.275h.04c.388-.734 1.334-1.51 2.747-1.51 2.937 0 3.477 1.935 3.477 4.448V25z"
								/>
							</svg>
						</a>
					</div>
				)}
			</footer>
		</GoogleReCaptchaProvider>
	);
};

const WrappedPayroll = ({ data, location }) => (
	<DefaultLayout siteSettings={data.siteSettings} className="bg-white">
		<PayrollPage
			data={data}
			location={location}
			phoneNumber={data.siteSettings.phoneNumber}
		/>
	</DefaultLayout>
);

export default WrappedPayroll;
export const query = graphql`
	query PayrollPage($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPayrollPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			id
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			showSocialButton
			showClientNumber
			intoTitle
			formTitle
			formId
			ctaText
			copyrightText
			image {
				alt
				url
			}
			smallImage {
				alt
				url
			}
			content {
				links
				value
			}
		}
		datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			logo {
				url
			}
		}
	}
`;
