// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { TrustpilotBusinessReviewHorizontal } from '../components/atoms/trustpilot-business-review';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { FeefoTestimonials } from '../components/molecules/feefo';

import { CardContainer } from '../components/organisms/card-container';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { Drawer } from '../components/atoms/drawer';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { Testimonials } from '../components/organisms/testimonials';
import { TwoColText } from '../components/molecules/two-col-text';
import { Title } from '../components/atoms/title';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { useFilters } from '../hooks/filters';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { parseDatoLocale } from '../utils/locale';
import { HrefLang } from '../components/templates/hrefLang';
import CaseStudySearch from '../components/organisms/case-study-search';
import { NoResultFound } from '../components/organisms/no-search-result';

const CaseStudiesPage = ({ location, data, locale }) => {
	const [searchData, setSearchData] = useState(null);
	const [serachFilter, setSearchFilter] = useState('');
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	React.useEffect(() => {
		if (
			searchData?.industryValue ||
			searchData?.serviceValue ||
			searchData?.businessSizeValue
		) {
			setSearchFilter(
				`${
					searchData?.industryValue
						? `, industry: {eq: "${
								searchData?.industryValue || ''
						  }"}`
						: ''
				} ${
					searchData?.serviceValue
						? `, services: {anyIn: [${
								searchData.serviceValue || ''
						  }]}`
						: ''
				}  ${
					searchData?.businessSizeValue
						? `, businessSize: {matches: {pattern: "^(?=.*${
								searchData.businessSizeValue || ''
						  }).*"}}`
						: ''
				}`
			);
		} else {
			setSearchFilter('');
		}
	}, [searchData]);
	const pageTotalQuery = `_allCaseStudyPagesMeta( filter: {slug: {neq: null} ${serachFilter} }, locale: $locale, fallbackLocales: ${
		parseDatoLocale[locale] || 'en'
	}) {
		count 
		}`;
	const caseStudyQuery = ({
		queryName,
		filter,
		skip,
	}) => `query allCaseStudyPages($locale: SiteLocale = ${
		parseDatoLocale[locale] || 'en'
	}) {
			${queryName}( filter: {slug: {neq: null} ${serachFilter} }, first: 12, orderBy: ${filter}, skip: ${skip}, locale: $locale, fallbackLocales: ${
		parseDatoLocale[locale] || 'en'
	})  {
				id
				title
				clientName
				slug
				businessSize
    			industry {
     			 name
      			 id
  				  }
  				services {
				name
   				id 
					}
				introduction
				thumbnail {
					responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 500, h: 300}, sizes:"(max-width: 640px) 400px, 800px") {
						srcSet
						sizes
						src
						width
						height
						alt
						base64
						bgColor
					}
				}
				categories {
					name
					id
				}
			}
				${pageTotalQuery}
	}`;

	const {
		loading,
		error,
		pagination,
		setPagination,
		items,
		totalSearchCount,
	} = useFilters({
		queryName: 'allCaseStudyPages',
		query: caseStudyQuery,
		data: data?.allDatoCmsCaseStudyPage,
		itemLimit: 12,
	});
	// get all business sizes and group them
	const businessSizes = items.map((item) => item.businessSize);
	const businessSizesGrouped = businessSizes.reduce((acc, curr) => {
		if (!acc[curr]) {
			acc[curr] = 1;
		} else {
			acc[curr] += 1;
		}
		return acc;
	}, {});
	let servicesGrouped = {};
	if (searchData?.businessSizeValue !== '') {
		const servicesIncludes = items.map((item) => item.services);
		const services = servicesIncludes.flat();
		servicesGrouped = services.reduce((acc, curr) => {
			if (!acc[curr?.id]) {
				acc[curr?.id] = 1;
			} else {
				acc[curr?.id] += 1;
			}
			return acc;
		}, {});
	}

	const metaContent = {
		noIndex: data.datoCmsCaseStudiesHubPage?.noIndex,
		noFollow: data.datoCmsCaseStudiesHubPage?.noFollow,
		metaInformation: data.datoCmsCaseStudiesHubPage?.metaInformation,
		fallbackTitle: data.datoCmsCaseStudiesHubPage?.title,
		fallbackDescription: data.datoCmsCaseStudiesHubPage?.longIntroduction,
		canonicalUrl:
			data.datoCmsCaseStudiesHubPage?.canonicalUrl || location.href,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.caseStudyHub,
			location,
			data: data.datoCmsCaseStudiesHubPage,
		}),
	};

	const CaseStudyContainerContent = {
		totalCount: totalSearchCount,
		cards: items,
	};

	const adviceBannerContent = {
		...data.datoCmsCaseStudiesHubPage.adviceBanner,
		title: data.datoCmsCaseStudiesHubPage.adviceBanner.title.value.document
			.children,
	};

	const testimonialsContent = {
		...data.datoCmsCaseStudiesHubPage.testimonials,
		title: data.datoCmsCaseStudiesHubPage.testimonials.title.value.document
			.children,
		slides: data.datoCmsCaseStudiesHubPage.testimonials.slides.map(
			(slide) => ({
				...slide,
				tags: slide.tags.split(','),
			})
		),
	};

	const twoColumnText = {
		...data.datoCmsCaseStudiesHubPage.twoColumnText,
		title: data.datoCmsCaseStudiesHubPage.twoColumnText.title.value.document
			.children,
		content:
			data.datoCmsCaseStudiesHubPage.twoColumnText.content.value.document
				.children,
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsCaseStudiesHubPage.internal.type,
		data.datoCmsCaseStudiesHubPage
	);
	const { locale: urlLocale } = data.datoCmsCaseStudiesHubPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsCaseStudiesHubPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Title
				title={data.datoCmsCaseStudiesHubPage.heading}
				subtitle={data.datoCmsCaseStudiesHubPage.subheading}
			/>
			<TrustpilotBusinessReviewHorizontal
				className="justify-center !mt-0 px-3"
				textClassName="text-center"
			/>
			{urlLocale !== 'ca' && (
				<CaseStudySearch
					title={data.datoCmsCaseStudiesHubPage.searchTitle}
					industry={data.allDatoCmsCaseStudyIndustry.nodes}
					service={data.allDatoCmsCaseStudyService.nodes}
					setData={setSearchData}
					businessSizesGrouped={businessSizesGrouped}
					servicesGrouped={servicesGrouped}
				/>
			)}
			{CaseStudyContainerContent.cards.length > 0 ? (
				<CardContainer
					variant="ViewAllFilters"
					cardContainerContent={{
						...CaseStudyContainerContent,
						id: 'casestudy-container',
						title: 'Case Study',
						to: '/case-studies',
					}}
					cardVariant="CaseStudy"
					loading={loading}
					error={error}
					setPagination={setPagination}
					pagination={pagination}
				/>
			) : (
				<NoResultFound />
			)}
			{data.datoCmsCaseStudiesHubPage.testimonialsDisplayOnPage && (
				<Testimonials
					drawerRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}
			<TwoColText
				drawerRef={adviceFormRef}
				textColTextContent={twoColumnText}
			/>
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />

			{urlLocale !== 'en-CA' ? (
				<FeefoTestimonials locale={urlLocale} />
			) : null}

			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>
			<TrustpilotReviews
				locale={urlLocale}
				className="pt-12 !pb-0"
				wrapperClassName="pb-12 border-b-1 border-b-blue-200"
			/>

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedCaseStudies = ({ location, data, pageContext }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<CaseStudiesPage
			location={location}
			data={data}
			locale={pageContext.locale}
		/>
	</DefaultLayout>
);

export default WrappedCaseStudies;

export const query = graphql`
	query allCaseStudies($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsCaseStudiesHubPage(locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			internal {
				type
			}
			noIndex
			noFollow
			canonicalUrl
			searchTitle
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			heading
			subheading
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			testimonialsDisplayOnPage
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			twoColumnText {
				id
				title {
					value
				}
				content {
					value
				}
				primaryLinkCopy
				primaryLink
				primaryButtonOpenForm
				secondaryLinkCopy
				secondaryLink
			}
		}
		allDatoCmsCaseStudyPage(
			limit: 12
			sort: { order: DESC, fields: created }
			filter: { locale: { eq: $locale } }
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				title
				clientName
				slug
				introduction
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				categories {
					name
					id
				}
			}
		}
		allDatoCmsCaseStudyIndustry(filter: { locale: { eq: $locale } }) {
			nodes {
				name
				id
				originalId
			}
		}
		allDatoCmsCaseStudyService(filter: { locale: { eq: $locale } }) {
			nodes {
				id
				name
				originalId
			}
		}
	}
`;
