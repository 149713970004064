// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';

import { ClientLogos } from '../components/atoms/client-logos';
import { Drawer } from '../components/atoms/drawer';

import { FeefoTestimonials } from '../components/molecules/feefo';
import { ImageContent } from '../components/molecules/image-content';
import { MainHeader } from '../components/molecules/main-header';
import { MainHeaderCA } from '../components/molecules/main-header-ca';
import { OurSoftware } from '../components/molecules/our-software';
import { ParallaxSlider } from '../components/molecules/parallax-slider';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { CardContainer } from '../components/organisms/card-container';
import { SuccessForm } from '../components/organisms/success-form';
import { Testimonials } from '../components/organisms/testimonials';

import { useDrawer } from '../contexts/drawer-context';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchema,
} from '../utils/seo-schema';

import { ClientLogosCA } from '../components/atoms/client-logos-ca';
import ModalWrapper from '../components/glu/molecules/modal-wrapper';
import { PeninsulaModalForm } from '../components/organisms/modal-peninsula-now';
import { HrefLang } from '../components/templates/hrefLang';
import useModal from '../hooks/modal';
import usePeninsulaNow from '../hooks/peninsula-now';
import { HeroMediaVideoCampaignCA } from '../components/molecules/hero-media-video-campaign-ca';
import BottomContentHome from '../components/organisms/bottom-content-home';
import TestimonialsVideoCampaignCA from '../components/organisms/testimonials-video-campaign-ca';
import { checkMediaTypes } from '../utils/check-media';

const IndexPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const { locale } = data.datoCmsHomepage;
	const metaContent = {
		noIndex: data.datoCmsHomepage.noIndex,
		noFollow: data.datoCmsHomepage.noFollow,
		metaInformation: data.datoCmsHomepage.metaInformation,
		fallbackTitle: data.datoCmsHomepage.title,
		fallbackDescription: data.datoCmsHomepage.longIntroduction,
		canonicalUrl: data.datoCmsHomepage?.canonicalUrl || location.href,
	};

	const headerCTAContent = {
		...data.datoCmsHomepage.mainHeader,
		header: data.datoCmsHomepage.mainHeader.header.value.document.children,
		media: data.datoCmsHomepage.heroSectionCa?.media || null,
	};

	const headerCTAContent2 = {
		...data.datoCmsHomepage.heroSectionCa,
		primaryHeaderText:
			data.datoCmsHomepage.heroSectionCa?.primaryHeaderText,
		secondaryHeaderText:
			data.datoCmsHomepage.heroSectionCa?.secondaryHeaderRows[0]
				?.secondaryHeaderRow,
		primaryVideo: data.datoCmsHomepage.heroSectionCa?.media[0]?.media,
	};

	const blogContainerContent = {
		...data.datoCmsHomepage.blogContainer,
		cards: data.allDatoCmsBlogPage.nodes,
	};

	const packagesContainer = {
		...data.datoCmsHomepage.packagesContainer,
		title: data.datoCmsHomepage.packagesContainer.title.value.document
			.children,
	};

	const testimonialsContent = {
		...data.datoCmsHomepage.testimonials,
		title: data.datoCmsHomepage.testimonials.title.value.document.children,
		slides: data.datoCmsHomepage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const testimonialsCAContent = {
		title: data.datoCmsHomepage?.testimonialsCa?.title,
		subText: data.datoCmsHomepage?.testimonialsCa?.subText,
		primaryLinkCopy: data.datoCmsHomepage?.testimonialsCa?.primaryLinkCopy,
		testimonialCards:
			data.datoCmsHomepage?.testimonialsCa?.testimonialCards,
	};

	const adviceBannerCAContent = {
		bottomSectionTitle: data.datoCmsHomepage.adviceBannerCa?.title,
		bottomSectionPrimaryLinkCopy:
			data.datoCmsHomepage.adviceBannerCa?.primaryLinkCopy,
		bottonSectionImage: {
			url: data.datoCmsHomepage.adviceBannerCa?.image?.url,
		},
	};

	const adviceBannerContent = {
		...data.datoCmsHomepage.adviceBanner,
		title: data.datoCmsHomepage.adviceBanner.title.value.document.children,
	};

	// for CA only
	const customFormFieldContent = {
		customFormTitle:
			data.datoCmsHomepage.customFormField[0]?.customFormTitle || null,
		customFormSubtitle:
			data.datoCmsHomepage.customFormField[0]?.customFormSubtitle || null,
		customFormButton:
			data.datoCmsHomepage.customFormField[0]?.customFormButton || null,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.home,
			location,
		}),
		image: getImageSeoSchema(headerCTAContent?.primaryImage),
	};
	const ref = React.useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	const { isOnline, modalOnline } = usePeninsulaNow();
	const openModal = () => {
		setModalOpen(true);
	};

	const heroMedia = data.datoCmsHomepage.heroSectionCa?.media;
	const { isTwinImg, isVideo } = checkMediaTypes(heroMedia);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsHomepage.allSeoLocales} home />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{locale === 'en-CA' ? (
				<>
					{isTwinImg && (
						<MainHeaderCA
							urlLocale={locale}
							drawerRef={adviceFormRef}
							{...headerCTAContent}
						/>
					)}

					{isVideo && (
						<HeroMediaVideoCampaignCA
							{...headerCTAContent}
							heroVideoCampaignCAContent={headerCTAContent2}
							location={location}
							modalRef={adviceFormRef}
							drawerRef={adviceFormRef}
							className="lg:-mt-16 xl:-mt-5 2xl:mt-0 2xl:pt-1"
							firstChildClassName="lg:-mb-20 xl:-mb-11 xl:-mb-[4rem] 2xl:-mb-[6rem]"
							titleClassName=""
							subTitleClassName="text-[#1D2951] xl:text-[24px]"
						/>
					)}
					<ClientLogosCA
						pathname={location.pathname}
						className="hidden lg:block md:-mt-20 md:-mb-5 lg:-mt-12 lg:-mb-10 xl:-mt-16 xl:-mb-12 "
					/>
					<ClientLogosCA
						pathname={location.pathname}
						className="block lg:hidden"
						isMobile
					/>
				</>
			) : (
				<>
					<MainHeader
						urlLocale={locale}
						drawerRef={adviceFormRef}
						{...headerCTAContent}
						reference={reference}
						response={response}
						setReference={setReference}
						setResponse={setResponse}
					/>
					<ClientLogos pathname={location.pathname} />
				</>
			)}

			<ImageContent
				drawerRef={adviceFormRef}
				imageContent={data.datoCmsHomepage.imageContent}
			/>
			<ParallaxSlider parallaxSliderContent={packagesContainer} />
			{locale !== 'en-CA' && (
				<Testimonials
					drawerRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}
			{locale !== 'en-CA' ? <FeefoTestimonials locale={locale} /> : null}
			<OurSoftware
				drawerRef={adviceFormRef}
				ourSoftwareContent={data.datoCmsHomepage.ourSoftware}
			/>

			{locale === 'en-CA' && (
				<>
					{data.datoCmsHomepage?.displayOnPageTestimonialsCa && (
						<TestimonialsVideoCampaignCA
							className="py-5 sm:py-0"
							firstChildClassName="lg:pt-[3rem] lg:pb-[2rem]"
							testimonialsVideoCampaignCA={testimonialsCAContent}
							drawerRef={adviceFormRef}
						/>
					)}

					{data.datoCmsHomepage.displayOnPageAdviceBannerCa && (
						<BottomContentHome
							bottomContentHome={adviceBannerCAContent}
							drawerRef={adviceFormRef}
							className="pt-5 pb-3 lg:pb-0 "
							firstChildClassName="bg-gradient-to-l from-[#3B56E0] via-[#9CAAF0] to-[#9DABF1] h-64 w-full"
						/>
					)}
				</>
			)}

			{locale !== 'en-CA' ? (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBannerContent}
				/>
			) : null}

			{locale !== 'en-CA' ? <TrustpilotReviews locale={locale} /> : null}

			{locale !== 'en-IE' && (
				<CardContainer
					cardContainerContent={{
						...blogContainerContent,
						to: '/resource-hub/blogs',
						toAll: '/resource-hub/blogs',
					}}
					cardVariant="Blog"
					variant="Center"
					location={location.pathname}
				/>
			)}
			{locale === 'en-CA' &&
			!data.datoCmsHomepage.displayOnPageAdviceBannerCa &&
			adviceBannerContent !== null ? (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBannerContent}
				/>
			) : null}
			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							pageName="Homepage"
							setReference={setReference}
							setResponse={setResponse}
							openModal={openModal}
							isOnline={modalOnline}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							pageName="Homepage"
							setReference={setReference}
							setResponse={setResponse}
							openModal={openModal}
							isOnline={modalOnline}
							customFormFieldContent={customFormFieldContent}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}

			{isModalOpen && (
				<ModalWrapper>
					<div
						className="relative 2xl:w-7/12 xl:w-[100%]  m-auto opacity-100 h-auto w-full 2xl:rounded-md shadow-xl bg-white overflow-auto"
						ref={ref}
					>
						<button
							title="Close Peninsula Now Modal"
							type="button"
							onClick={() => setModalOpen(false)}
							className="absolute flex top-[5px] text-2xl bg-tranparent font-bold right-[10px] opacity-100 border-2 border-brand-pale-200 h-9 w-9 items-center justify-center rounded-full"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
								y="0px"
								width="20"
								height="20"
								viewBox="0 0 50 50"
								className="font-bold fill-current text-brand-pale-200"
							>
								<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
							</svg>
						</button>
						<PeninsulaModalForm isOnline={isOnline} />
					</div>
				</ModalWrapper>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedHome = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<IndexPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedHome;

export const query = graphql`
	query home($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsHomepage(locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			mainHeader {
				header {
					value
				}
				secondaryHeader
				secondaryHeaderRows {
					secondaryHeaderRow
				}
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
				showInlineForm
				formPosition
				note
			}
			imageContent {
				id
				title
				content
				linkCopy
				link
				image {
					url
					gatsbyImageData(
						width: 600
						height: 500
						layout: CONSTRAINED
					)
					alt
				}
				showCtas
				imageLeftAligned
			}
			packagesContainer {
				title {
					value
				}
				slides {
					title
					subText
					tickList {
						id
						text
					}
					primaryLinkCopy
					primaryLink
					secondaryLinkCopy
					secondaryLink
					image {
						alt
						url
						gatsbyImageData(
							width: 600
							height: 460
							layout: CONSTRAINED
						)
					}
				}
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			testimonialsCa {
				title {
					value
				}
				subText
				testimonialCards {
					content
					name
					job
					profile {
						url
					}
				}
				primaryLinkCopy
			}
			displayOnPageTestimonialsCa
			adviceBannerCa {
				title
				primaryLinkCopy
				image {
					url
				}
			}
			displayOnPageAdviceBannerCa
			ourSoftware {
				title
				content
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
				mobileImage {
					alt
					url
					gatsbyImageData(
						width: 230
						height: 277
						layout: CONSTRAINED
					)
				}
				desktopImage {
					alt
					url
					gatsbyImageData(
						width: 280
						height: 568
						layout: CONSTRAINED
					)
				}
				tickList {
					id
					text
					subText
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			blogContainer {
				id
				title
				subText
				buttonCopy
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			heroSectionCa {
				primaryHeaderText {
					value
				}
				secondaryHeaderRows {
					secondaryHeaderRow
				}
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				secondaryLinkOpenForm
				media {
					media {
						url
					}
				}
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
		}
		allDatoCmsBlogPage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: { slug: { ne: null }, locale: { eq: $locale } }
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
				externalLink
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
