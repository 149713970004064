// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BreadCrumbs } from '../../components/atoms/breadcrumbs';
import { ClientLogos } from '../../components/atoms/client-logos';
import { Drawer } from '../../components/atoms/drawer';
import { Title } from '../../components/atoms/title';
import { TrustpilotBusinessReviewHorizontal } from '../../components/atoms/trustpilot-business-review';
import { FeefoTestimonials } from '../../components/molecules/feefo';
import { SectorCard } from '../../components/molecules/sector-card';
import { TextWithImageContainer } from '../../components/molecules/text-with-image-container';
import { TrustpilotReviews } from '../../components/molecules/trustpilot-reviews';
import { AdviceBanner } from '../../components/organisms/advice-banner';
import { AdviceForm } from '../../components/organisms/advice-form';
import { FAQ } from '../../components/organisms/faq';
import { IconCards } from '../../components/organisms/icon-cards-safecheck';
import { SuccessForm } from '../../components/organisms/success-form';
import { Testimonials } from '../../components/organisms/testimonials';
import { Head } from '../../components/templates/head';
import { HrefLang } from '../../components/templates/hrefLang';
import { useDrawer } from '../../contexts/drawer-context';
import { DefaultLayout } from '../../layouts/default';
import { getBreadcrumbsSeoSchema } from '../../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../../utils/url-helpers';

const HRSectorPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const {
		noIndex,
		noFollow,
		metaInformation,
		heading,
		subHeading,
		canonicalUrl,
	} = data.datoCmsSectorsPage;
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.Sectors,
			location,
			data: data.datoCmsSectorsPage,
		}),
	};
	const metaContent = {
		noIndex,
		noFollow,
		metaInformation,
		canonicalUrl,
	};
	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsSectorsPage.internal.type,
		data.datoCmsSectorsPage
	);
	const pattern = /[[\]"]+/g;
	const { slug } = data.datoCmsSectorsPage;
	const allSectors = data.allDatoCmsChildSectorPage.nodes.filter((c) =>
		c.category.replace(pattern, '').includes(slug)
	);
	const showHealthSafetyTrustpilotReview = slug.includes('health-safety');
	const testimonialsContent = {
		...data.datoCmsSectorsPage.testimonials,
		title: data.datoCmsSectorsPage.testimonials.title.value.document
			.children,
		slides: data.datoCmsSectorsPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};
	const adviceBannerContent = {
		...data.datoCmsSectorsPage.adviceBanner,
		title: data.datoCmsSectorsPage.adviceBanner.title.value.document
			.children,
	};

	const adviceBannerContentTwo = {
		...data.datoCmsSectorsPage.adviceBanner2,
		title: data.datoCmsSectorsPage.adviceBanner2.title.value.document
			.children,
	};
	const uspBanner = data.datoCmsSectorsPage.cards;
	const faqContent = {
		title: data.datoCmsSectorsPage.faqContainer.title,
		subText: data.datoCmsSectorsPage.faqContainer.subText,
		questions: data.datoCmsSectorsPage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};
	const textWithImageContainer = {
		...data.datoCmsSectorsPage.textWithImageContainer,
		title: data.datoCmsSectorsPage.textWithImageContainer.title.value
			.document.children,
		firstContent:
			data.datoCmsSectorsPage.textWithImageContainer.firstContent,
	};
	const { locale } = data.datoCmsSectorsPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsSectorsPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Title
				title={heading}
				subtitle={subHeading}
				className="px-3 mx-auto max-w-m-screen !lg:px-2"
			/>
			<div className="px-3 mx-auto max-w-m-screen lg:px-8 -mt-16">
				{data.datoCmsSectorsPage.showTrustpilotWidget && (
					<>
						{showHealthSafetyTrustpilotReview ? (
							<TrustpilotReviews
								locale={locale}
								pageName="safe-check"
							/>
						) : (
							<TrustpilotBusinessReviewHorizontal className="justify-center" />
						)}
					</>
				)}
			</div>
			<section className="px-4 mx-auto max-w-m-screen my-10">
				<h2 className="mb-6 text-2xl">
					{data.datoCmsSectorsPage.title}
				</h2>
				<div className="gap-4 md:grid md:grid-cols-2 lg:grid-cols-3">
					{allSectors.map((sector, index) => (
						<SectorCard
							key={sector.id}
							cardContent={sector}
							index={index}
						/>
					))}
				</div>
			</section>
			<Testimonials
				drawerRef={adviceFormRef}
				hasBottomCtas={false}
				testimonialsContent={testimonialsContent}
			/>
			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>
			<section className="px-4 mx-auto max-w-m-screen mt-16">
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					cardtitle={data.datoCmsSectorsPage.headingTitle}
					headerType="simple"
				/>
			</section>
			<ClientLogos pathname={location.pathname} />

			<div className="bg-white w-full py-1">
				<FAQ faqContent={faqContent} />
			</div>
			{/* two column image banner */}
			<TextWithImageContainer
				textWithImageContainerContent={textWithImageContainer}
				showOnlyFirst
			/>
			{locale !== 'en-CA' && data.datoCmsSectorsPage.showFeefoWidget && (
				<FeefoTestimonials locale={locale} />
			)}
			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContentTwo}
			/>
			{locale !== 'en-CA' && data.displayTrustpilotBanner ? (
				<TrustpilotReviews locale={locale} />
			) : null}
			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceForm
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessForm reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

const WrappedHRSectorPage = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<HRSectorPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedHRSectorPage;

export const query = graphql`
	query allSectors($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsSectorsPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			internalTitle
			slug
			noFollow
			noIndex
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			internal {
				type
			}
			metaInformation {
				description
				title
				twitterCard
			}
			canonicalUrl
			heading
			subHeading
			showTrustpilotWidget
			title
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			adviceBanner {
				content
				ctaLabel
				ctaLink
				ctaOpenForm
				id
				locale
				title {
					value
				}
				internalTitle
			}
			headingTitle
			cards {
				id
				title
				icon
				content {
					value
				}
			}
			showClientLogos
			faqContainer {
				id
				internalTitle
				locale
				title
				subText
				questions {
					locale
					question
					originalId
					id
					answer {
						value
					}
				}
			}
			showFeefoWidget
			adviceBanner2 {
				content
				ctaLabel
				ctaLink
				ctaOpenForm
				id
				internalTitle
				locale
				title {
					value
				}
			}
			displayTrustpilotBanner
			textWithImageContainer {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
			}
		}
		allDatoCmsChildSectorPage(
			sort: { fields: title, order: ASC }
			filter: { locale: { eq: $locale } }
		) {
			nodes {
				slug
				title
				intro
				buttonText
				category
				mainHeader {
					primaryImage {
						gatsbyImageData
					}
				}
			}
		}
	}
`;
