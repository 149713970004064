// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default';
import { Head } from '../components/templates/head';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceBannerPricingCA } from '../components/organisms/advice-banner-pricing-ca';
import { PricingSection } from '../components/organisms/pricing-section';
import { Testimonials } from '../components/organisms/testimonials';
import { TestimonialsPricingCA } from '../components/organisms/testimonials-pricing-ca';
import { pageTypes } from '../utils/url-helpers';

import { FeefoTestimonials } from '../components/molecules/feefo';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { PricingSectionCA } from '../components/organisms/pricing-section-ca';
import { Drawer } from '../components/atoms/drawer';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';

import { getLocale } from '../utils/locale';
import { HrefLang } from '../components/templates/hrefLang';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

// markup
const PricingPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const { locale: urlLocale } = data.datoCmsPricingPage;

	const metaContent = {
		noIndex: data.datoCmsPricingPage.noIndex,
		noFollow: data.datoCmsPricingPage.noFollow,
		metaInformation: data.datoCmsPricingPage.metaInformation,
		fallbackTitle: data.datoCmsPricingPage.title,
		fallbackDescription: data.datoCmsPricingPage.longIntroduction,
		canonicalUrl: data.datoCmsPricingPage?.canonicalUrl || location.href,
	};

	const testimonialsContent = {
		...data.datoCmsPricingPage.testimonials,
		title: data.datoCmsPricingPage.testimonials.title.value.document
			.children,
		slides: data.datoCmsPricingPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const adviceBanner = {
		...data.datoCmsPricingPage.adviceBanner,
		title: data.datoCmsPricingPage.adviceBanner.title.value.document
			.children,
	};

	const pricingContent = {
		title: data.datoCmsPricingPage.heading,
		subtitle: data.datoCmsPricingPage.subheading,
		cards: data.datoCmsPricingPage.cards,
		quoteButtonCopy: data.datoCmsPricingPage.quoteButtonCopy,
		feeGuide: JSON.parse(data.datoCmsPricingPage.feeGuide),
	};

	const pricingContentCanada = {
		title: data.datoCmsPricingPage.heading,
		subtitle: data.datoCmsPricingPage.subheading,
		cards: data.datoCmsPricingPage.canadaCards,
		quoteButtonCopy: data.datoCmsPricingPage.quoteButtonCopy,
		title2: data.datoCmsPricingPage.headingTwo,
		subtitle2: data.datoCmsPricingPage.subheadingTwo,
		table: data.datoCmsPricingPage.pricingTable,
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsPricingPage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsPricingPage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsPricingPage?.customFormField[0]?.customFormButton,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.pricing,
			location,
		}),
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPricingPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{urlLocale === 'en-CA' ? (
				<PricingSectionCA
					pricingContent={pricingContentCanada}
					locale={urlLocale}
					customFormField={customFormField}
				/>
			) : (
				<PricingSection
					locale={urlLocale}
					pricingContent={pricingContent}
				/>
			)}
			{urlLocale === 'en-CA' ? (
				<TestimonialsPricingCA
					testimonialsContent={testimonialsContent}
				/>
			) : (
				<Testimonials
					drawerRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}
			{urlLocale !== 'en-CA' ? (
				<FeefoTestimonials locale={urlLocale} />
			) : null}
			{urlLocale === 'en-CA' ? (
				<AdviceBannerPricingCA adviceBannerContent={adviceBanner} />
			) : (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBanner}
				/>
			)}

			{locale !== 'ca' ? <TrustpilotReviews locale={urlLocale} /> : null}

			{urlLocale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedPricing = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<PricingPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedPricing;

export const query = graphql`
	query pricing($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPricingPage(locale: { eq: $locale }) {
			originalId
			locale
			feeGuide
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			id
			heading
			subheading
			headingTwo
			subheadingTwo
			quoteButtonCopy
			cards {
				id
				title
				cardType
				prePriceCopy
				postPriceCopy
				tickListHeader
				tickList {
					id
					text
				}
			}
			quoteButtonCopy
			pricingTable {
				title
				humanResources
				healthAndSafety
				fullCompliance
			}
			canadaCards {
				id
				title
				cardType
				description
				price
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
